import AuthenticatedView from "./_filters/AuthenticatedView";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation/menu/NavigationMenu";
import ProtectedRoute from "../components/authorizations/protectedComponents/route/ProtectedRoute";
import React, { useEffect, useState } from "react";
import SpinPage from "./spinPage/SpinPage";
import UnAuthenticatedView from "./_filters/UnAuthenticatedView";
import { AccountManagersView } from "./accountManagers/AccountManagersView";
import { ActivateAccountView } from "./activateAccount/ActivateAccountView";
import { BackTop, Layout } from "antd";
import { CampaignsAdminView } from "./campaignsAdmin/CampaignsAdminView";
import { CampaignsPartnerView } from "./campaignsPartner/CampaignsPartnerView";
import { CampaignsView } from "./callCenter/campaigns/CampaignsView";
import { CitiesDictionaryView } from "./dictionaries/cities/CitiesDictionaryView";
import { ClassifiersView } from "./callCenter/classifiers/ClassifiersView";
import { CompaniesView } from "./companies/CompaniesView";
import { CountriesDictionaryView } from "./dictionaries/countries/CountriesDictionaryView";
import { CouriersApiMappingDictionaryView } from "./dictionaries/couriersApiMapping/CouriersApiMappingDictionaryView";
import { CouriersDictionaryView } from "./dictionaries/couriers/CouriersDictionaryView";
import { CouriersStatusesDictionaryView } from "./dictionaries/couriersStatuses/CouriersStatusesDictionaryView";
import { CourierStatusMappingView } from "./courierStatusMapping/CourierStatusMappingView";
import { createAbsoluteUrl } from "../helpers/UrlCreator";
import { CreateNewOrderView } from "./createNewOrder/CreateNewOrderView";
import { DashboardView } from "./dashboard/DashboardView";
import { DistrictsDictionaryView } from "./dictionaries/districts/DistrictsDictionaryView";
import { EmployeesView } from "./employees/EmployeesView";
import { FaqView } from "./faq/FaqView";
import { FinanceAdminView } from "./financeAdmin/FinanceAdminView";
import { FinanceView } from "./finance/FinanceView";
import { HeaderView } from "./header/HeaderView";
import { IChangeLang } from "../models/IChangeLang";
import { InProgressView } from "./inProgess/InProgressView";
import { InstancesView } from "./callCenter/instances/InstancesView";
import { K8ServicesView } from "./administration/k8Services/K8ServicesView";
import { LandingPageView } from "./landingPage/LandingPageView";
import { LeadsRedirectionView } from "./callCenter/leadsRedirection/LeadsDirectionView";
import { LeadsView } from "./leads/LeadsView";
import { LocationsDictionaryView } from "./dictionaries/locations/LocationsDictionaryView";
import { LoginView } from "./login/LoginView";
import { MappingClassifiersView } from "./callCenter/mappingClassifiers/MappingClassifiersView";
import { navPath } from "../common/ResourceDef";
import { NewsAdminView } from "./news/newsAdmin/NewsAdminView";
import { NewsView } from "./news/news/NewsView";
import { NotFoundView } from "./notFound/NotFoundView";
import { OffersDetailsView } from "./offers/offerDetails/OffersDetailsView";
import { OffersListView } from "./offers/offersList/OffersListView";
import { OrderDetailsView } from "./orders/orderDetails/OrderDetailsView";
import { OrdersView } from "./orders/orders/OrdersView";
import { PagesView } from "./pages/PagesView";
import { PartnersView } from "./partners/PartnersView";
import { PaymentMethodsDictionaryView } from "./dictionaries/paymentMethods/PaymentMethodsDictionaryView";
import { PostCodesDictionaryView } from "./dictionaries/postCodes/PostCodsDictionaryView";
import { ProductCategoriesDictionaryView } from "./dictionaries/productCategories/ProductCategoriesDictionaryView";
import { ProductDetailsView } from "./products/productDetails/ProductDetailsView";
import { ProductsView } from "./products/products/ProductsView";
import { ProfileView } from "./profile/ProfileView";
import { ProvincesDictionaryView } from "./dictionaries/provinces/ProvincesDictionaryView";
import { Redirect, Route, Switch } from "react-router-dom";
import { RegisterView } from "./register/RegisterView";
import { ResetPasswordView } from "./resetPassword/ResetPasswordView";
import { RolePermissionsView } from "./administration/rolePermissions/RolePermissionsView";
import { SalesChannelsDictionaryView } from "./dictionaries/salesChannels/SalesChannelsDictionaryView";
import { SalesResalesView } from "./sales/resale/SalesResalesView";
import { SessionExpiredView } from "./sessionExpired/SessionExpiredView";
import { StatisticsView } from "./statistics/statistics-view";
import { StatusesDictionaryView } from "./dictionaries/statuses/StatusesDictionaryView";
import { SubdistrictsDictionaryView } from "./dictionaries/subdistricts/SubdistrictsDictionaryView";
import { SupportView } from "./support/SupportView";
import { SystemClassifiersView } from "./callCenter/systemClassifiers/SystemClassifiersView";
import { SystemVariablesDictionaryView } from "./dictionaries/systemVariables/SystemVariablesDictionaryView";
import { ToolsView } from "./tools/ToolsView";
import { UserPermissionType } from "../models/IAppUserInfoModel";
import { UsersInformationView } from "./_common/usersInformations/UsersInformationView";
import { UsersRoleView } from "./administration/usersRole/UsersRoleView";
import { UsersView } from "./administration/users/UsersView";
import { WebhooksView } from "./callCenter/webhooks/WebhooksView";
import { WebSocketView } from "./administration/webSocket/WebSocketView";
import { ResalesP2View } from "./sales/p2/ResalesP2View";
import { SettingsView } from "./administration/settings/SettingsView";
import { TableProvider } from "./statistics/lib/table-state";
import { FiltersProvider } from "./statistics/lib/filters-state";
import { VerificationToolView } from "./tools/components/verification-tool/view";
import { TrafficSourceDictionaryView } from "./dictionaries/trafficSource/TrafficSourceDictionaryView";
import { RemarketingView } from "./administration/remarketing/RemarketingView";
import { USER_ROLES } from "../common/enums/UserRoles";
import { useSelector } from "react-redux";
import { LoginCCView } from "./login_cc/LoginView";

const { Sider, Content } = Layout;

export const MainView = (props: IChangeLang) => {
  const [collapsedMenu, setCollapsedMenu] = useState(true);
  const [currentMenuItem, setCurrentMenuItem] = useState("");
  const isCCSimplified = useSelector((state: any) => {
    const userRole = state.commonState.appUserInfo.id_users_role;

    return userRole === USER_ROLES.CC_SIMPLIFIED;
  });

  const handleMenuClick = (e: any) => {
    setCurrentMenuItem(e.key);

    if (window.innerWidth < 992) {
      setCollapsedMenu(true);
    }
  };

  useEffect(() => {
    if (isCCSimplified) {
      window.document.dispatchEvent(
        new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true,
        })
      );
    }
  }, [isCCSimplified]);

  return (
    <Layout>
      <Helmet>
        <script
          src="https://website-widgets.pages.dev/dist/sienna.min.js"
          defer
        ></script>
      </Helmet>

      <AuthenticatedView>
        <Sider
          breakpoint="lg"
          collapsed={collapsedMenu}
          collapsedWidth="0"
          onCollapse={() => setCollapsedMenu(!collapsedMenu)}
          style={{
            backgroundColor: isCCSimplified ? "#12487C" : "#1e253b",
          }}
          className={`${isCCSimplified ? "simplified-version" : ""}`}
        >
          <Navigation
            handleMenuClick={handleMenuClick}
            current={currentMenuItem}
            navigationItems={[]}
          />
        </Sider>
        <Layout>
          <HeaderView changeLang={props.changeLang} />

          <Content>
            <Switch>
              <ProtectedRoute
                permission={UserPermissionType.DashboardView}
                path={createAbsoluteUrl(navPath.dashboard)}
                render={() => <DashboardView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.OffersListView}
                exact
                path={createAbsoluteUrl(navPath.offers)}
                render={() => <OffersListView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.OffersDetailsView}
                path={createAbsoluteUrl(navPath.offersId)}
                render={(props) => <OffersDetailsView {...props} />}
              />
              {/* As we are using context for managing table & filters state, we have to wrap view with provider */}
              <ProtectedRoute
                permission={UserPermissionType.StatisticsView}
                path={createAbsoluteUrl(navPath.statistics)}
                render={() => (
                  <TableProvider>
                    <FiltersProvider>
                      <StatisticsView />
                    </FiltersProvider>
                  </TableProvider>
                )}
              />
              <ProtectedRoute
                permission={UserPermissionType.LeadsView}
                path={createAbsoluteUrl(navPath.leads)}
                render={(props) => <LeadsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.FinanceView}
                path={createAbsoluteUrl(navPath.finance)}
                render={() => <FinanceView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.FinanceAdminView}
                path={createAbsoluteUrl(navPath.financeAdminId)}
                render={(props) => <FinanceAdminView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.EmployeesView}
                path={createAbsoluteUrl(navPath.employees)}
                render={() => <EmployeesView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.AccountManagersView}
                path={createAbsoluteUrl(navPath.account_managers)}
                render={() => <AccountManagersView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ProductsView}
                path={createAbsoluteUrl(navPath.products)}
                render={() => <ProductsView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ProductsView}
                exact
                path={createAbsoluteUrl(navPath.product_details)}
                render={(props) => <ProductDetailsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ProductsView}
                exact
                path={createAbsoluteUrl(navPath.product_details_id)}
                render={(props) => <ProductDetailsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.PagesView}
                path={createAbsoluteUrl(navPath.pages)}
                render={() => <PagesView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CompaniesView}
                path={createAbsoluteUrl(navPath.companies)}
                render={() => <CompaniesView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.PartnersView}
                path={createAbsoluteUrl(navPath.partners_id)}
                render={(props) => <PartnersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.PartnersView}
                path={createAbsoluteUrl(navPath.partners)}
                render={(props) => <PartnersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.UsersView}
                path={createAbsoluteUrl(navPath.salesRemarketing)}
                render={() => <RemarketingView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.UsersView}
                path={createAbsoluteUrl(navPath.administrationUsers)}
                render={() => <UsersView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.K8ServicesView}
                path={createAbsoluteUrl(navPath.k8_services)}
                render={() => <K8ServicesView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SettingsView}
                path={createAbsoluteUrl(navPath.settings)}
                render={() => <SettingsView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.UsersInformationView}
                path={createAbsoluteUrl(navPath.users_information_id)}
                render={(props) => <UsersInformationView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.UsersRoleView}
                path={createAbsoluteUrl(navPath.administrationRole)}
                render={() => <UsersRoleView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.WebSocketView}
                path={createAbsoluteUrl(navPath.administrationWebSocket)}
                render={(props) => <WebSocketView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.RolePermissionsView}
                path={createAbsoluteUrl(navPath.administrationPermissionNameId)}
                render={(props) => <RolePermissionsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ToolsView}
                path={createAbsoluteUrl(navPath.tools)}
                render={() => <ToolsView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.NewsView}
                path={createAbsoluteUrl(navPath.news)}
                render={() => <NewsView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.NewsAdminView}
                path={createAbsoluteUrl(navPath.news_admin)}
                render={() => <NewsAdminView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SupportView}
                path={createAbsoluteUrl(navPath.support)}
                render={() => <SupportView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.FaqView}
                path={createAbsoluteUrl(navPath.faq)}
                render={() => <FaqView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.VerificationToolView}
                path={createAbsoluteUrl(navPath.verificationTool)}
                render={() => <VerificationToolView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CampaignsAdminView}
                path={createAbsoluteUrl(navPath.campaigns_admin)}
                render={() => <CampaignsAdminView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CampaignsPartnerView}
                path={createAbsoluteUrl(navPath.campaigns_partner)}
                render={() => <CampaignsPartnerView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.InstancesView}
                path={createAbsoluteUrl(navPath.cc_instances)}
                render={() => <InstancesView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.LeadsRedirectionView}
                path={createAbsoluteUrl(navPath.cc_leads_direction)}
                render={() => <LeadsRedirectionView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CallCenterCampaignsView}
                exact
                path={createAbsoluteUrl(navPath.cc_campaigns)}
                render={(props) => <CampaignsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CallCenterCampaignsDetailsView}
                path={createAbsoluteUrl(navPath.cc_campaignsId)}
                render={(props) => <CampaignsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ClassifiersVies}
                exact
                path={createAbsoluteUrl(navPath.cc_classifiers)}
                render={(props) => <ClassifiersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ClassifiersVies}
                exact
                path={createAbsoluteUrl(
                  navPath.cc_classifiersInstanceInstance_idCampaignCampaign_id
                )}
                render={(props) => <ClassifiersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ClassifiersVies}
                exact
                path={createAbsoluteUrl(
                  navPath.cc_classifiersInstanceInstance_id
                )}
                render={(props) => <ClassifiersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ClassifiersVies}
                exact
                path={createAbsoluteUrl(
                  navPath.cc_classifiersCampaignCampaign_id
                )}
                render={(props) => <ClassifiersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.MappingClassifiersView}
                path={createAbsoluteUrl(navPath.li_classifiers_cc)}
                render={() => <MappingClassifiersView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.WebhooksView}
                path={createAbsoluteUrl(navPath.cc_webhooks)}
                render={() => <WebhooksView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ProfileView}
                path={createAbsoluteUrl(navPath.profile)}
                render={() => <ProfileView />}
              />
              <ProtectedRoute
                exact
                permission={UserPermissionType.CreateNewOrderView}
                path={createAbsoluteUrl(navPath.createNewOrder)}
                render={(props) => <CreateNewOrderView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CreateNewOrderView}
                path={createAbsoluteUrl(navPath.createNewOrder_cc_id)}
                render={(props) => <CreateNewOrderView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.OrderView}
                path={createAbsoluteUrl(navPath.orders)}
                render={(props) => <OrdersView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.OrderDetailsView}
                path={createAbsoluteUrl(navPath.order_details_id)}
                render={(props) => <OrderDetailsView {...props} />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CourierStatusMapping}
                path={createAbsoluteUrl(navPath.courierStatusMapping)}
                render={() => <CourierStatusMappingView />}
              />
              {UserPermissionType.DashboardView > -1 ? (
                <Redirect
                  from="/"
                  exact
                  to={createAbsoluteUrl(navPath.dashboard)}
                />
              ) : (
                <Redirect
                  from="/"
                  exact
                  to={createAbsoluteUrl("createNewOrder")}
                />
              )}
              <ProtectedRoute
                permission={UserPermissionType.CountriesDictionaryView}
                path={createAbsoluteUrl(navPath.countries_dictionary)}
                render={() => <CountriesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CitiesDictionaryView}
                path={createAbsoluteUrl(navPath.cities_dictionary)}
                render={() => <CitiesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.DistrictsDictionaryView}
                path={createAbsoluteUrl(navPath.districts_dictionary)}
                render={() => <DistrictsDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CouriersDictionaryView}
                path={createAbsoluteUrl(navPath.couriers_dictionary)}
                render={() => <CouriersDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.StatisticsView}
                path={createAbsoluteUrl(navPath.statuses_dictionary)}
                render={() => <StatusesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.LocationsDictionaryView}
                path={createAbsoluteUrl(navPath.locations_dictionary)}
                render={() => <LocationsDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.PostCodesDictionaryView}
                path={createAbsoluteUrl(navPath.posto_codes_dictionary)}
                render={() => <PostCodesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.PaymentMethodsDictionaryView}
                path={createAbsoluteUrl(navPath.payment_methods_dictionary)}
                render={() => <PaymentMethodsDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ProductCategoriesDictionaryView}
                path={createAbsoluteUrl(navPath.products_categories_dictionary)}
                render={() => <ProductCategoriesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SalesChannelsDictionaryView}
                path={createAbsoluteUrl(navPath.sales_channels_dictionary)}
                render={() => <SalesChannelsDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SubdistrictsDictionaryView}
                path={createAbsoluteUrl(navPath.subdistricts_dictionary)}
                render={() => <SubdistrictsDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.ProvincesDictionaryView}
                path={createAbsoluteUrl(navPath.provinces_dictionary)}
                render={() => <ProvincesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SystemVariablesDictionaryView}
                path={createAbsoluteUrl(navPath.system_variables)}
                render={() => <SystemVariablesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.TrafficSourcesDictionaryView}
                path={createAbsoluteUrl(navPath.traffic_sources)}
                render={() => <TrafficSourceDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CouriersApiMappingDictionaryView}
                path={createAbsoluteUrl(navPath.couriers_api_mapping)}
                render={() => <CouriersApiMappingDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SystemClassifiersView}
                path={createAbsoluteUrl(navPath.system_classifiers)}
                render={() => <SystemClassifiersView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.CouriersStatusesDictionaryView}
                path={createAbsoluteUrl(navPath.couriers_statuses)}
                render={() => <CouriersStatusesDictionaryView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SalesResaleView}
                path={createAbsoluteUrl(navPath.sales_resale)}
                render={() => <SalesResalesView />}
              />
              <ProtectedRoute
                permission={UserPermissionType.SalesP2View}
                path={createAbsoluteUrl(navPath.sales_p2)}
                render={() => <ResalesP2View />}
              />
              <Redirect
                from="/"
                exact
                to={createAbsoluteUrl(navPath.dashboard)}
              />
              <Route
                path={createAbsoluteUrl("in-progress")}
                render={() => <InProgressView />}
              />
              <Route render={() => <NotFoundView />} />
            </Switch>
          </Content>
        </Layout>
      </AuthenticatedView>
      <SpinPage>
        <UnAuthenticatedView>
          <Switch>
            <Route
              path={createAbsoluteUrl("homePage")}
              render={() => <LandingPageView changeLang={props.changeLang} />}
            />
            <Route
              path={createAbsoluteUrl("login")}
              render={() => <LoginView />}
            />
            <Route
              path={createAbsoluteUrl("loginCallCenter")}
              render={() => <LoginCCView />}
            />
            <Route
              exact
              path={createAbsoluteUrl("register/:referrer")}
              render={(props) => <RegisterView {...props} />}
            />
            <Route
              path={createAbsoluteUrl("register")}
              render={(props) => <RegisterView {...props} />}
            />
            <Route
              exact
              path={createAbsoluteUrl("resetPassword/:password_token")}
              render={(props) => <ResetPasswordView {...props} />}
            />
            <Route
              path={createAbsoluteUrl("resetPassword")}
              render={(props) => <ResetPasswordView {...props} />}
            />
            <Route
              path={createAbsoluteUrl("activate-account/:activate_token")}
              render={(props) => <ActivateAccountView {...props} />}
            />
            <Route
              path={createAbsoluteUrl("sessionExpired")}
              render={() => <SessionExpiredView />}
            />

            <Redirect from="/" exact to={createAbsoluteUrl("homePage")} />

            <Route
              render={() => (isCCSimplified ? <LoginCCView /> : <LoginView />)}
            />
          </Switch>
        </UnAuthenticatedView>
      </SpinPage>
      <BackTop />
    </Layout>
  );
};
